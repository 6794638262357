import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Tree } from '../tree';
import { object } from 'prop-types';
import { Input, SvgBtSearch } from '@flomni/components';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { URL } from '../editor/constants';
import { ROUTE, routes } from '../../configs/routes';

export const Search2 = ({ container }) => {
    const css = useStyles(styles);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const key = new URLSearchParams(history.location.search).get('key');

    const [search, setSearch] = useState(key || '');
    const [searchResult, setSearchResult] = useState(null);

    useEffect(() => {
        axios.get(`${URL.SEARCH}/?query=${key}&language=${i18n.language}`).then((response) => {
            setSearchResult(response.data.pages);
        })
    }, [key]);

    const onKeyUp = (event) => {
        if (search && event.key === 'Enter') {
            history.push(`${routes[ROUTE.SEARCH]}?key=${search}`);
        }
    };

    return (
        <div className={css('container')}>
            <div className={css('header')}>
                {!!searchResult?.length && (
                    <div className={css('title')}>
                        {t('hlp:numberResults', { count: searchResult.length, key: key })}
                    </div>
                )}
                <div className={css('search-line')}>
                    <SvgBtSearch />
                    <Input
                        onKeyUp={onKeyUp}
                        classes={{ root: css('search-input-root'), input: css('search-input') }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('hlp:searchPlaceholder')}
                    />
                </div>
            </div>
            {!searchResult?.length && (
                <div>
                    <div className={css('no-result-container')}>
                        <div>
                            <Trans i18nKey="hlp:sorryCouldNotFindKey" key={search}>
                                0<span className={css('bold-key')}>{{ key }}</span>2
                            </Trans>
                        </div>
                        <div>{t('hlp:tryDifferentSearch')}</div>
                    </div>
                </div>
            )}
            {!!searchResult?.length && (
                <div className={css('page')}>
                    <Tree mainClass={css('menu')} container={container} />
                    <div className={css('separator')} />
                    <div>
                        {searchResult?.map((result, index) => (
                            <div key={index} className={css('result-item')}>
                                <div className={css('result-page')}>
                                    <Link to={`/pages/${result.url}`}>{result.title}</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={css('separator')} />
                    <div />
                </div>
            )}
        </div>
    );
};

Search2.propTypes = {
    container: object.isRequired
};
