import React, { createContext, useReducer } from 'react';

const initialState = {
    isShowGlobalLoader: false,
    messages: [],
    menu: [],
    pages: [],
    publicMenu: []
};
const store = createContext(initialState);
const { Provider } = store;

const HelpCenterProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            switch (action.type) {
                case 'setMenu':
                    return {
                        ...state,
                        menu: [...action.menu]
                    };
                case 'setPages':
                    return {
                        ...state,
                        pages: [...action.pages]
                    };
                case 'setRelationsCache':
                    return {
                        ...state,
                        relationsCache: {...action.relationsCache}
                    };
                case 'setIsShowGlobalLoader':
                    return {
                        ...state,
                        isShowGlobalLoader: action.show
                    };
                case 'setPublicMenu':
                    return {
                        ...state,
                        publicMenu: [...action.menu.tree]
                    };
                case 'showSuccessMessage':
                    return {
                        ...state,
                        messages: [
                            ...state.messages,
                            {
                                message: action.message,
                                view: 'success'
                            }
                        ]
                    };
                case 'showWarningMessage':
                    return {
                        ...state,
                        messages: [
                            ...state.messages,
                            {
                                message: action.message,
                                view: 'warning'
                            }
                        ]
                    };
                case 'clearMessages':
                    return {
                        ...state,
                        messages: []
                    };
                default:
                    throw new Error();
            }
        },
        {
            ...initialState
        }
    );

    return <Provider value={{ ...state, dispatch }}>{children}</Provider>;
};

export { store, HelpCenterProvider };
